'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.contacts.factory:ContactRelationTypeManager

 # @description

###
angular
  .module 'mundoAdmin.contacts'
  .factory 'ContactRelationTypeManager', [
    'MundoDefaultManager'
    (MundoDefaultManager) ->
      ContactRelationTypeManagerBase =  new MundoDefaultManager()
      ContactRelationTypeManagerBase.setUrl('contacts/relations/types')

      ContactRelationTypeManagerBase
  ]
